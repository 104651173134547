import { Link } from 'gatsby';
import React from 'react';

import SEO from '../components/seo';

function NotFoundPage() {
  return (
    <div className="p-5 md:p-10">
      <SEO title="404 Not found" />
      <h2 className="mb-8 leading-none text-4xl md:text-5xl">404</h2>
      <p>This page doesn&apos;t exist.</p>
      <Link to="/" className="text-blue-800 text-xs">
        Return to the homepage.
      </Link>
    </div>
  );
}

export default NotFoundPage;
